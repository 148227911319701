import Logo from 'src/components/Logo/Logo';
import NavButton from 'src/components/Navigation/components/NavButton/NavButton';
import { useEffect, useContext } from 'react';
import Context from '../Context/Context';

const Navigation = () => {
  const { isOpenMenu, setIsOpen } = useContext(Context);

  useEffect(() => {
    const { body } = document;
    if (isOpenMenu && body) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'visible';
    }
  }, [isOpenMenu]);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, [isOpenMenu]);

  return (
    <header
      id="header"
      className={`w-full px-4 py-4 absolute top-0 left-0 ${
        isOpenMenu ? 'bg-brandGray fixed z-50' : 'bg-transparent'
      }`}
    >
      <div className="container mx-auto flex flex-wrap justify-between relative z-50">
        <div className="w-24 md:w-32 h-auto relative transform translate-y-1">
          <Logo />
        </div>
        <NavButton isOpenMenu={isOpenMenu} setIsOpen={setIsOpen} />
      </div>
    </header>
  );
};

export default Navigation;
