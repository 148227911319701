import Link from 'next/link';
import React, { useContext } from 'react';
import Context from 'src/components/Context/Context';

const Logo = () => {
  const { setIsOpen } = useContext(Context);

  return (
    <Link href="/">
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className="logo-filter logo-hover text-white focus:outline-none"
        aria-label="Link to home page"
        onClick={() => setIsOpen(false)}
        onKeyDown={() => setIsOpen(false)}
        role="link"
        tabIndex="0"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.2"
          viewBox="150 550 2250 1350"
          height="100%"
          width="100%"
          className="fill-current"
        >
          <g clipPath="url(#cp1)">
            <path
              fillRule="evenodd"
              className="vector-blue"
              d="m1686.7 1259.7c0-29.8 1.1-324.5-0.4-328.4l-105.6-106.1c-5.9-5.9-35.3-34.5-37.9-38.4q305.1 0 610.2 0c17.6 0 61-0.9 76.3 0 5.3 0.2 6.1 2.8 9.3 6 5.3 5.3 9.9 9.9 15.2 15.2 20.6 20.6 40.5 40.5 61.1 61.1 3.5 3.5 60.3 58.2 60.3 61.7v257.4c0 2.9-6.4 7.8-8.4 9.8l-99.6 99.6c-6.3 6.3-11.9 11.8-18.1 18.1-3.3 3.3-5.8 5.8-9.1 9.1-2 2-6.9 8.3-9.8 8.3h-398.5v398.5c0 2.9-6.4 7.8-8.4 9.8l-99.6 99.6c-3.8 3.9-32.6 34.4-37 35.5q0-308.4 0-616.8zm485.1-80.3c3.1-3.1 5.5-5.5 8.6-8.6 5.8-5.8 49.8-47.8 49.8-51.2v-119.2c0-2.6-17.9-19.7-21.5-23.3l-12.4-12.4c-0.1-0.1-0.3-0.3-0.5-0.5l-21-20.9c-2.4-2.3-7.3-9.1-11.1-10.8-1.6-0.8-0.2-0.8-3.1-0.8h-328.9v255.5h330.8c2.9 0 7.4-5.9 9.3-7.8z"
            />
            <path
              className="vector-blue"
              d="m198.3 1334.1h686.5c2.2 0 2.9-0.7 2.9-2.9v-140.1c0-2.2-0.7-2.9-2.9-2.9h-541.5v-54.3h373.7c2.2 0 2.9-0.7 2.9-2.9v-141.1c0-2.2-0.7-2.9-2.9-2.9h-373.7v-54.3h541.5c2.2 0 2.9-0.7 2.9-2.9v-140.1c0-2.2-0.7-2.9-2.9-2.9h-686.5z"
            />
            <path
              className="vector-blue"
              d="m198.3 730.5h1977.6l-36-36.4c-12.3-12.3-23.9-24-36.2-36.3l-63.5-63.4c-2-2-7.3-8.8-10.2-8.8h-1685.8c-2.3 0-3.6 2.1-5 3.6l-13.8 13.8c-3.3 3.3-5.8 5.8-9.1 9.1-1.7 1.7-2.7 2.6-4.4 4.1-0.2 0.2-0.4 0.4-0.5 0.5l-4.2 4.4c-11.1 12.3-24.2 24.3-36.2 36.3l-24.4 24.1c-0.2 0.2-0.4 0.4-0.5 0.5l-29.4 29.7c-2.8 2.8-17.3 15.7-18.4 18.8z"
            />
            <path
              className="vector-blue"
              d="m942.1 1334.1h145.8v-401.4h340.4c3.5 0 44.4 42.9 50.8 49.3 10.3 10.3 6.4 9.2 6.4 37.5v302.2c0 4.8-1.5 12.4 2.9 12.4h143v-390c0-16.2 1.3-13.1-9.5-22.8l-11.8-12c-0.2-0.2-0.4-0.4-0.5-0.5l-28.1-28.1c-0.8-0.7-0.9-0.9-1.5-1.5l-11.4-11.4c-0.1-0.2-0.3-0.4-0.5-0.5l-12.4-12.4c-0.7-0.7-0.9-0.9-1.4-1.4l-4.3-4.3c-0.2-0.2-0.8-0.8-1-1l-31-30.9c-0.1-0.2-0.3-0.4-0.4-0.5-0.2-0.2-0.8-0.8-1-1l-24.8-24.8c-0.1-0.1-0.3-0.3-0.4-0.5l-2.4-2.3c-1.7-1.1-2-1.4-4.4-1.4h-542.5z"
            />
            <path
              className="vector-orange"
              d="m344.2 1534.3h1284.4c2.2 0 2.8-0.7 2.8-2.9v-142h-1433.1l135.7 136.1c2 2 7.3 8.8 10.2 8.8z"
            />
            <path
              className="vector-orange"
              d="m1887 1534.3c15.8 0 138.7 1.3 143.2-0.7l30.9-30.2c0.1-0.2 0.3-0.4 0.4-0.5 0.2-0.1 0.4-0.3 0.5-0.5l47.7-47.6c0.1-0.2 0.3-0.4 0.5-0.5 0.1-0.2 0.3-0.4 0.4-0.5 0.9-0.9 1-1 2-1.9l9.3-9.7c0.2-0.1 0.4-0.3 0.5-0.5l3.3-3.3c0.2-0.2 0.4-0.4 0.5-0.5 0.9-0.9 1.3-1.3 2.4-2.4l11-10.9c0.1-0.2 0.3-0.4 0.4-0.5 0.2-0.2 0.4-0.4 0.5-0.5l33.5-34.2h-287z"
            />
          </g>
        </svg>
      </a>
    </Link>
  );
};

export default Logo;
