import '../styles/globals.css'
import { useState } from "react";
import Layout from "src/components/Layout/Layout";
import Context from "../src/components/Context/Context"

function MyApp({ Component, pageProps }) {
  const [isOpenMenu, setIsOpen] = useState(false);

  return (
    <>
      <Context.Provider value={{ isOpenMenu, setIsOpen }}>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </Context.Provider>
    </>
  )
}

export default MyApp
