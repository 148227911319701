import ScrollTopButton from "src/components/ScrollTopButton/ScrollTopButton";
import Navigation from "src/components/Navigation/Navigation";
import NavMenu from "src/components/Navigation/components/NavMenu/NavMenu";
import Footer from "src/components/Footer/Footer";
import OrganizationSchema from "src/components/StructuredData/OrganizationSchema";

const Layout = ({ children }) => (
    <>
      <OrganizationSchema />
      <ScrollTopButton />
      <Navigation />
      <NavMenu />
      <main id="main_content">
        {children}
      </main>
      <Footer />
    </>
  );

export default Layout;