import Head from 'next/head'

const OrganizationSchema = () => {
    const socialNetworks = [
        'https://www.facebook.com/epr.com.ua',
        'https://www.instagram.com/epr.com.ua',
        'https://www.linkedin.com/company/electropromremont-ltd/',
    ];

    const schema = JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'Organization',
        url: "https://www.epr.com.ua",
        name: "ELEKTROPROMREMONT",
        telephone: "38(097)0894554",
        sameAs: socialNetworks,
        logo: "https://www.epr.com.ua/assets/logo.png",
        email: "info@epr.com.ua",
        address: {
            '@type': 'PostalAddress',
            addressCountry: "UA",
            addressLocality: "Dnipro",
            addressRegion: "Dnipropetrovs'ka oblast",
            postalCode: "49000",
            streetAddress: "Akademika Beleliubskoho St, 14",
        }
    });


    return (
        <Head>
            <script type="application/ld+json">{schema}</script>
        </Head>
    );
};

export default OrganizationSchema;
