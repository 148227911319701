import React, { memo, useContext } from 'react';
import Link from "next/link";
import { useRouter } from "next/router";
import Context from "src/components/Context/Context";

const ButtonAsLink = ({
  link = '/',
  styling = "default",
  additionalStyle = "",
  locales
}) => {
  const defaultTitle = {
    ua: "Детальніше",
    ru: "Подробнее",
    en: "More details"
  }
  const { locale } = useRouter();
  const buttonTitle = locales ? locales[locale] : defaultTitle[locale];
  const { setIsOpen } = useContext(Context);


  const styles = {
    default: "border-gray-900 hover:text-white hover:bg-gray-900 border-solid",
    primary: "bg-primary border-white hover:bg-white hover:text-primary hover:border-primary",
    secondary: "bg-secondary border-secondary hover:bg-white hover:text-secondary hover:border-white",
    secondary_reverse: "border-secondary text-secondary hover:bg-secondary hover:text-white",
  }
  return (
    <Link href={link}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className={`my-6 px-4 py-3 border-2 font-semibold tracking-wider text-sm rounded-md min-w-[12.5rem] 
        inline-flex justify-center items-center uppercase
        ${additionalStyle} ${styles[styling]}`}
        onClick={() => setIsOpen(false)}
        onKeyPress={() => setIsOpen(false)}
        role="button"
        tabIndex="0"
      >
        {buttonTitle}
      </a>
    </Link>
  );
};

export default memo(ButtonAsLink);