import MenuLink from 'src/components/Footer/components/MenuLink/MenuLink';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Context from 'src/components/Context/Context';
import Link from 'next/link';
import { useContext } from 'react';
import Logo from 'src/components/Logo/Logo';
import { NavMenuLinks, TitleData } from './locales/data';

const EmailAsLink = dynamic(() =>
  import('src/components/Contacts/components/EmailAsLink/EmailAsLink'),
);
const AddressAsLink = dynamic(() =>
  import('src/components/Contacts/components/AddressAsLink/AddressAsLink'),
);
const SocialIcons = dynamic(() =>
  import('src/components/SocialIcons/SocialIcons'),
);
const ButtonAsLink = dynamic(() =>
  import('src/components/ButtonAsLink/ButtonAsLink'),
);
const PhoneAsLink = dynamic(() =>
  import('src/components/Contacts/components/PhoneAsLink/PhoneAsLink'),
);
const Copyright = dynamic(() =>
  import('src/components/Footer/components/Copyright/Copyright'),
);

const Footer = () => {
  const { locale } = useRouter();
  const { setIsOpen } = useContext(Context);

  return (
    <footer id="footer" className="text-white px-4 bg-brandGray">
      <div className="container mx-auto flex items-center flex-col lg:pt-20">
        <div className="flex flex-wrap flex-col lg:flex-row w-full lg:px-12">
          <div className="w-full lg:w-2/5 mt-10 lg:mt-0 justify-center items-center flex">
            <div className="w-32 lg:w-48 h-auto pb-10">
              <Logo />
            </div>
          </div>
          <div className="w-full lg:w-3/5 flex flex-col md:flex-row justify-between">
            {NavMenuLinks.map(({ title, links, link: titleLink }, index) => (
              <div className="pl-0 lg:pl-10" key={title[locale]}>
                <Link href={titleLink}>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    className="text-md tracking-wider pb-2 uppercase opacity-50 hover:opacity-100 menu-link"
                    onClick={() => setIsOpen(false)}
                    onKeyDown={() => setIsOpen(false)}
                    role="button"
                    tabIndex="0"
                  >
                    {title[locale]}
                  </a>
                </Link>
                <ul
                  className={`${
                    index !== NavMenuLinks.length - 1 ? 'pb-10 lg:pb-0' : 'pb-0'
                  }`}
                >
                  {links.map(({ link, locales }, indexLi) => (
                    <MenuLink
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={`${title[locale]}_${link}_${indexLi}`}
                      link={link}
                      locales={locales}
                      color="text-white"
                    />
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <hr className="bg-white w-full my-10 opacity-20" />

        <div className="flex items-center flex-col w-full lg:px-12">
          <div className="text-white text-center">
            <p className="opacity-50 pb-4 tracking-wider">
              {TitleData[locale].content_2}
            </p>
            <p className="text-2xl lg:text-4xl">
              {TitleData[locale].content_1}
            </p>
            <ButtonAsLink
              link="/contacts"
              styling="secondary_reverse"
              additionalStyle="min-w-[12rem]"
              locales={{
                ru: 'Напишите нам',
                en: 'Email Us',
                ua: 'Напишіть нам',
              }}
            />
          </div>
          <div className="flex flex-col lg:flex-row my-4 w-full justify-around">
            <EmailAsLink isAlignCenter isFooter />
            <PhoneAsLink isAlignCenter isFooter />
            <AddressAsLink isAlignCenter isFooter />
            <SocialIcons />
          </div>
        </div>
        <Copyright />
      </div>
    </footer>
  );
};

export default Footer;
