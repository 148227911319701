import {useRouter} from "next/router";
import NavButtonLocales from "./locales/data";

const NavButton = ({ isOpenMenu, setIsOpen }) => {
  const { locale } = useRouter();

  return (
    <button
      type="button"
      tabIndex="0"
      role="switch"
      aria-label="toggle menu"
      aria-checked="false"
      className="inline-flex flex-row items-center text-white hover:text-secondary focus:outline-none"
      onClick={() => setIsOpen(!isOpenMenu)}
    >
      <span className="sr-only">Open main menu</span>
      <span className="relative font-medium uppercase text-sm tracking-wider pr-4 transition-colors duration-300 ease-in-out">
        {isOpenMenu ? `${NavButtonLocales[locale].open}` : `${NavButtonLocales[locale].closed}`}
      </span>
      <span className="w-7 h-full relative inline-block">
        <span
          aria-hidden="true"
          className={` btn-hamburger ${isOpenMenu ? "h-0 before:rotate-45 after:-rotate-45" : "before:-translate-y-2 after:translate-y-2"}`}
        />
      </span>
    </button>
  );
};

export default NavButton;
