/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import MenuLink from 'src/components/Footer/components/MenuLink/MenuLink';
import dynamic from 'next/dynamic';
import ButtonAsLink from 'src/components/ButtonAsLink/ButtonAsLink';
import AddressAsLink from 'src/components/Contacts/components/AddressAsLink/AddressAsLink';
import Context from '../../../Context/Context';
import { NavMenuLinks } from '../../../Footer/locales/data';

const EmailAsLink = dynamic(() =>
  import('src/components/Contacts/components/EmailAsLink/EmailAsLink'),
);
const LocaleLinks = dynamic(() =>
  import('src/components/Navigation/components/LocaleLinks/LocaleLinks'),
);
const PhoneAsLink = dynamic(() =>
  import('src/components/Contacts/components/PhoneAsLink/PhoneAsLink'),
);

const NavMenu = () => {
  const { isOpenMenu, setIsOpen } = useContext(Context);
  const { locale, pathname } = useRouter();
  const containerRef = useRef(null);

  const CONTACTS_LOCALE = {
    ru: 'Контакты',
    en: 'Contacts',
    ua: 'Контакти',
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView(false);
    }
  }, [isOpenMenu]);

  return (
    <div
      className={`fixed top-0 left-0 right-0 bottom-0 bg-brandGray z-40 text-white pt-40 pb-10 px-4 overflow-auto
    ${isOpenMenu ? 'block bg-opacity-100' : 'hidden bg-opacity-0'}`}
    >
      <div
        ref={containerRef}
        className="container mx-auto h-full flex flex-col justify-between"
      >
        <div className="w-full flex flex-col lg:flex-row justify-between flex-grow">
          {NavMenuLinks.map(({ title, links, link: titleLink }) => (
            <ul key={title[locale]} className="pb-10 lg:pb-0">
              <Link href={titleLink}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  className="text-md tracking-wider pb-2 uppercase opacity-50 hover:opacity-100 menu-link"
                  onClick={() => setIsOpen(false)}
                  onKeyPress={() => setIsOpen(false)}
                  role="button"
                  tabIndex="0"
                >
                  {title[locale]}
                </a>
              </Link>
              {links.map(({ link, locales }, indexLi) => (
                <MenuLink
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={`${title[locale]}_${link}_${indexLi}`}
                  link={link}
                  locales={locales}
                  color="text-white"
                  size="text-xl"
                />
              ))}
            </ul>
          ))}
          <div className="flex flex-col pb-10 lg:pb-0 max-w-none lg:max-w-[22rem]">
            <h3 className="text-md tracking-wider pb-4 uppercase opacity-50">
              {CONTACTS_LOCALE[locale]}
            </h3>
            <EmailAsLink isTitle={false} />
            <PhoneAsLink isTitle={false} />
            <AddressAsLink isTitle={false} />
            {pathname !== '/contacts' && (
              <ButtonAsLink
                link="/contacts"
                additionalStyle="mr-auto"
                styling="secondary_reverse"
                locales={{
                  ru: 'Напишите нам',
                  en: 'Email Us',
                  ua: 'Напишіть нам',
                }}
              />
            )}
          </div>
        </div>
        <div className="py-20 lg:py-10">
          <LocaleLinks />
        </div>
      </div>
    </div>
  );
};

export default NavMenu;
