import React from 'react';
import { useRouter } from "next/router";
import Link from "next/link";
import Locales from "./locales/data";

const AddressAsLink = ({ isTitle = true, isAlignCenter = false, isFooter = false }) => {
  const { locale } = useRouter();

  const link = "https://www.google.com/maps/place/%D1%83%D0%BB.+%D0%90%D0%BA%D0%B0%D0%B4%D0%B5%D0%BC%D0%B8%D0%BA%D0%B0+%D0%91%D0%B5%D0%BB%D0%B5%D0%BB%D1%8E%D0%B1%D1%81%D0%BA%D0%BE%D0%B3%D0%BE,+14,+%D0%94%D0%BD%D0%B8%D0%BF%D1%80%D0%BE,+%D0%94%D0%BD%D0%B5%D0%BF%D1%80%D0%BE%D0%BF%D0%B5%D1%82%D1%80%D0%BE%D0%B2%D1%81%D0%BA%D0%B0%D1%8F+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C,+49038/@48.4815425,35.0116888,17z/data=!4m2!3m1!1s0x40dbe25adc277f71:0x3090f15c53cbb45a"

  return (
    <div className={`${isAlignCenter ? "text-center" : "text-left"} w-full xl:w-[270px]`}>
      {isTitle && (
        <p className="uppercase opacity-50 tracking-wider pt-4 lg:pt-0">{Locales[locale].title}</p>
      )}
      <div className={`inline-flex items-center ${isFooter ? "my-3" : "my-2"}`}>
        <Link href={link}>
          <a className="text-2xl menu-link" target="_blank" rel="noopener noreferrer">{Locales[locale].address}</a>
        </Link>
      </div>
    </div>
  );
};

export default AddressAsLink;