const Data = {
  ru: {
    title: 'Наш адрес',
    address: 'г. Днепр ул. Академика Белелюбского 14'
  },
  en: {
    title: 'Our address',
    address: '14 Academician Belelubsky street, Dnipro'
  },
  ua: {
    title: 'Наша Адреса',
    address: "м. Дніпро вул. Академіка Белелюбського 14"
  }
};


export default Data;