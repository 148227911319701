import React, { useContext } from 'react';
import { useRouter } from "next/router";
import Link from "next/link";
import Context from "src/components/Context/Context";

const MenuLink = ({ link, locales, color, size = "text-base" }) => {
  const { locale } = useRouter();
  const { setIsOpen } = useContext(Context);

  return (
    <li className="my-4">
      <Link href={link}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className={`${color} ${size} menu-link`}
          onClick={() => setIsOpen(false)}
          onKeyPress={() => setIsOpen(false)}
          role="button"
          tabIndex="0"
        >
          {locales[locale]}
        </a>
      </Link>
    </li>
  );
};

export default MenuLink;