const data = {
  ru: {
    open: "Закрыть",
    closed: "Меню"
  },
  en: {
    open: "Close",
    closed: "Menu"
  },
  ua: {
    open: "Закрити",
    closed: "Меню"
  }
}

export default data;
