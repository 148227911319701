import { useCallback } from "react";
import Arrow from "public/assets/icons/next.svg";
import useScrollPosition from "../../hooks/useScrollPosition";

const ScrollTopButton = () => {
  const scrollPosition = useScrollPosition();

  const scrollToTopHandler = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, [])

  if (scrollPosition < 900) return null;

  return (
    <button
      type="button"
      onClick={scrollToTopHandler}
      className="fixed w-12 h-12 rounded flex justify-center items-center z-50 right-2 lg:right-4 bottom-4 text-white text-3xl bg-secondary xl:hover:text-secondary xl:hover:bg-transparent border-secondary border-2 -rotate-90 p-2"
    >
      <Arrow />
    </button>
  );
};

export default ScrollTopButton;