// eslint-disable-next-line import/prefer-default-export
export const  NavMenuLinks = [
  {
    title: {
      ru: 'О КОМПАНИИ',
      en: 'ABOUT COMPANY',
      ua: "ПРО КОМПАНІЮ"
    },
    link: '/about_company',
    links: [
      {
        locales: {
          ru: 'О нас',
          en: 'About us',
          ua: "Про нас"
        },
        link: '/about_company/#about-us',
      },
      {
        locales: {
          ru: 'Производство',
          en: 'Production',
          ua: "Виробництво"

        },
        link: '/about_company/#production',
      },
      {
        locales: {
          ru: 'Закупки',
          en: 'Procurement',
          ua: "Закупівлі"
        },
        link: '/procurement',
      },
      {
        locales: {
          ru: 'Сертификаты',
          en: 'Certifications',
          ua: "Сертифікати"

        },
        link: '/about_company/#our-certificates',
      },
      {
        locales: {
          ru: 'Наша команда',
          en: 'Our team',
          ua: "Наша команда"

        },
        link: '/about_company/#our-team',
      },
      {
        locales: {
          ru: 'Вакансии',
          en: 'Vacancies',
          ua: "Вакансії"

        },
        link: '/about_company',
      },
    ],
  },
  {
    title: {
      ru: 'УСЛУГИ',
      en: 'SERVICES',
      ua: "ПОСЛУГИ"
    },
    link: '/services',
    links: [
      {
        locales: {
          ru: 'Ремонт электродвигателей',
          en: 'Repair electric motors',
          ua: "Ремонт електродвигунів"
        },
        link: '/services/#service-1',
      },
      {
        locales: {
          ru: 'Запасные части к двигателям',
          en: 'Spare parts for motors',
          ua: "Запасни частини для двигунів"

        },
        link: '/services/#service-4',
      },
      {
        locales: {
          ru: 'Выездные работы',
          en: 'Field work',
          ua: "Виїзні роботи"
        },
        link: '/services/#service-6',
      },
      {
        locales: {
          ru: 'Прочие работы',
          en: 'Other works',
          ua: "Інші роботи"
        },
        link: '/services/#service-7',
      },
    ]
  },
  {
    title: {
      ru: 'Наши партнеры',
      en: 'Our partners',
      ua: "Наші партнери"
    },
    link: '/our_partners',
    links: [
      {
        locales: {
          ru: 'Тепловая энергетика',
          en: 'Thermal energy',
          ua: "Теплова енергетика"
        },
        link: '/our_partners/#partner-1',
      },
      {
        locales: {
          ru: 'Атомная энергетика',
          en: 'Nuclear energy',
          ua: "Атомна енергетика"

        },
        link: '/our_partners/#partner-3',
      },
      {
        locales: {
          ru: 'Гидроэнергетика',
          en: 'Hydro energy',
          ua: "Гідроенергетика"
        },
        link: '/our_partners/#partner-2',
      },
      {
        locales: {
          ru: 'Угольные компании',
          en: 'Coal companies',
          ua: "Вугільні компанії"
        },
        link: '/our_partners/#partner-8',
      },
      {
        locales: {
          ru: 'Другие партнеры',
          en: 'More partners',
          ua: "Інші партнери"
        },
        link: '/our_partners',
      },
    ]
  },
];

export const CopyrightData = {
  ru: 'Авторское право © EPR, Inc. Все права защищены.',
  en: 'Copyright © EPR, Inc. All rights reserved.',
  ua: "Авторські права © EPR, Inc. Усі права захищені."
}

export const TitleData = {
  ru: {
    content_1: "ЭЛЕКТРОПРОМРЕМОНТ",
    content_2: "ДВИГАТЕЛЬ ВАШЕГО БИЗНЕСА",
  },
  en: {
    content_1: "ELEKTROPROMREMONT",
    content_2: "ENGINE OF YOUR BUSINESS",
  },
  ua: {
    content_1: "ЕЛЕКТРОПРОМРЕМОНТ",
    content_2: "ДВИГУН ВАШОГО БІЗНЕСУ",
  }
}